export default /*
File generated by js-routes 1.4.9
Based on Rails 7.0.8.4 routes of Stargate::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call({});
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// about_us => (/:path_prefix)(/:locale_param)/about-us(.:format)
  // function(options)
  aboutUsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"about-us",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// accept_api_product_recommendation => (/:path_prefix)(/:locale_param)/api/product_recommendations/:id/accept(.:format)
  // function(id, options)
  acceptApiProductRecommendationPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// account_center_devices => (/:path_prefix)(/:locale_param)/account_center/devices(.:format)
  // function(options)
  accountCenterDevicesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"account_center",false],[2,[7,"/",false],[2,[6,"devices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_center_purchases => (/:path_prefix)(/:locale_param)/account_center/purchases(.:format)
  // function(options)
  accountCenterPurchasesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"account_center",false],[2,[7,"/",false],[2,[6,"purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_center_settings => (/:path_prefix)(/:locale_param)/account_center/settings(.:format)
  // function(options)
  accountCenterSettingsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"account_center",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_center_user_info => (/:path_prefix)(/:locale_param)/account_center/user_info(.:format)
  // function(options)
  accountCenterUserInfoPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"account_center",false],[2,[7,"/",false],[2,[6,"user_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_catalog_imports => /admin/catalog_imports(.:format)
  // function(options)
  adminCatalogImportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"catalog_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_currency_conversion_rates => /admin/currency_conversion_rates(.:format)
  // function(options)
  adminCurrencyConversionRatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_conversion_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard => (/:path_prefix)(/:locale_param)/admin/dashboard(.:format)
  // function(options)
  adminDashboardPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_email_configs => /admin/email_configs(.:format)
  // function(options)
  adminEmailConfigsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_email_log => /admin/email_logs/:id(.:format)
  // function(id, options)
  adminEmailLogPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_logs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_email_logs => /admin/email_logs(.:format)
  // function(options)
  adminEmailLogsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_ghost_inspector => /admin/ghost_inspector(.:format)
  // function(options)
  adminGhostInspectorPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ghost_inspector",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_guest_users => /admin/guest_users(.:format)
  // function(options)
  adminGuestUsersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"guest_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_permission => /admin/permissions/:id(.:format)
  // function(id, options)
  adminPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_permissions => /admin/permissions(.:format)
  // function(options)
  adminPermissionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_promo_campaign_download => /admin/promo_campaigns/:promo_campaign_id/download(.:format)
  // function(promo_campaign_id, options)
  adminPromoCampaignDownloadPath: Utils.route([["promo_campaign_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"promo_campaigns",false],[2,[7,"/",false],[2,[3,"promo_campaign_id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_promo_campaigns => /admin/promo_campaigns(.:format)
  // function(options)
  adminPromoCampaignsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"promo_campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_search_test => (/:path_prefix)(/:locale_param)/admin/vstsearch/:id(.:format)
  // function(id, options)
  adminSearchTestPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"vstsearch",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_search_tests => (/:path_prefix)(/:locale_param)/admin/vstsearch(.:format)
  // function(options)
  adminSearchTestsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"vstsearch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// affiliates => (/:path_prefix)(/:locale_param)/affiliates(.:format)
  // function(options)
  affiliatesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"affiliates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// affiliates_faq => (/:path_prefix)(/:locale_param)/affiliates/faq(.:format)
  // function(options)
  affiliatesFaqPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"affiliates",false],[2,[7,"/",false],[2,[6,"faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// affiliates_program_details => (/:path_prefix)(/:locale_param)/affiliates/program-details(.:format)
  // function(options)
  affiliatesProgramDetailsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"affiliates",false],[2,[7,"/",false],[2,[6,"program-details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api => (/:path_prefix)(/:locale_param)/api/payment/purchases/:order_number(.:format)
  // function(order_number, options)
  apiPath: Utils.route([["path_prefix",false],["locale_param",false],["order_number",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"order_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_access_codes => (/:path_prefix)(/:locale_param)/api/access_codes(.:format)
  // function(options)
  apiAccessCodesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"access_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_accounts_check_password => (/:path_prefix)(/:locale_param)/api/accounts/check_password(.:format)
  // function(options)
  apiAccountsCheckPasswordPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"check_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_admin_guest_users => (/:path_prefix)(/:locale_param)/api/admin/guest_users(.:format)
  // function(options)
  apiAdminGuestUsersPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"guest_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_admin_guest_users_resends_order_confirmation => (/:path_prefix)(/:locale_param)/api/admin/guest_users/resends_order_confirmation(.:format)
  // function(options)
  apiAdminGuestUsersResendsOrderConfirmationPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"guest_users",false],[2,[7,"/",false],[2,[6,"resends_order_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_admin_guest_users_searches => (/:path_prefix)(/:locale_param)/api/admin/guest_users/searches(.:format)
  // function(options)
  apiAdminGuestUsersSearchesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"guest_users",false],[2,[7,"/",false],[2,[6,"searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_admin_permission => (/:path_prefix)(/:locale_param)/api/admin/permissions/:id(.:format)
  // function(id, options)
  apiAdminPermissionPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_admin_permissions => (/:path_prefix)(/:locale_param)/api/admin/permissions(.:format)
  // function(options)
  apiAdminPermissionsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_admin_role_switcher => (/:path_prefix)(/:locale_param)/api/admin/role_switcher(.:format)
  // function(options)
  apiAdminRoleSwitcherPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"role_switcher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_asset => (/:path_prefix)(/:locale_param)/api/assets/:id(.:format)
  // function(id, options)
  apiAssetPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"assets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_asset_licenses => (/:path_prefix)(/:locale_param)/api/assets/:asset_id/licenses(.:format)
  // function(asset_id, options)
  apiAssetLicensesPath: Utils.route([["path_prefix",false],["locale_param",false],["asset_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"assets",false],[2,[7,"/",false],[2,[3,"asset_id",false],[2,[7,"/",false],[2,[6,"licenses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_assets_online_resource => (/:path_prefix)(/:locale_param)/api/assets/online_resources/:asset_id(.:format)
  // function(asset_id, options)
  apiAssetsOnlineResourcePath: Utils.route([["path_prefix",false],["locale_param",false],["asset_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"assets",false],[2,[7,"/",false],[2,[6,"online_resources",false],[2,[7,"/",false],[2,[3,"asset_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_authorization_request_signings => (/:path_prefix)(/:locale_param)/api/authorization_request_signings(.:format)
  // function(options)
  apiAuthorizationRequestSigningsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"authorization_request_signings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_bulk_download_csv => (/:path_prefix)(/:locale_param)/api/bulk_download_csv(.:format)
  // function(options)
  apiBulkDownloadCSVPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_download_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_bulk_purchase_request => (/:path_prefix)(/:locale_param)/api/bulk_purchase_requests/:id(.:format)
  // function(id, options)
  apiBulkPurchaseRequestPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_purchase_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_country => (/:path_prefix)(/:locale_param)/api/countries/:id(.:format)
  // function(id, options)
  apiCountryPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"countries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_discount_offers => (/:path_prefix)(/:locale_param)/api/discount_offers(.:format)
  // function(options)
  apiDiscountOffersPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"discount_offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_express_checkout_asset => (/:path_prefix)(/:locale_param)/api/express_checkout_assets/:id(.:format)
  // function(id, options)
  apiExpressCheckoutAssetPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"express_checkout_assets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_fraud_prevention_refund_checks => (/:path_prefix)(/:locale_param)/api/fraud_prevention/refund_checks(.:format)
  // function(options)
  apiFraudPreventionRefundChecksPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fraud_prevention",false],[2,[7,"/",false],[2,[6,"refund_checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_fraud_prevention_refund_notices => (/:path_prefix)(/:locale_param)/api/fraud_prevention/refund_notices(.:format)
  // function(options)
  apiFraudPreventionRefundNoticesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fraud_prevention",false],[2,[7,"/",false],[2,[6,"refund_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_institutions => (/:path_prefix)(/:locale_param)/api/institutions(.:format)
  // function(options)
  apiInstitutionsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"institutions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_library_index => (/:path_prefix)(/:locale_param)/api/library(.:format)
  // function(options)
  apiLibraryIndexPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"library",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_line_item => (/:path_prefix)(/:locale_param)/api/line_items/:id(.:format)
  // function(id, options)
  apiLineItemPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"line_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_line_items => (/:path_prefix)(/:locale_param)/api/line_items(.:format)
  // function(options)
  apiLineItemsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"line_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_order_history => (/:path_prefix)(/:locale_param)/api/order-history/:id(.:format)
  // function(id, options)
  apiOrderHistoryPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"order-history",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_order_history_index => (/:path_prefix)(/:locale_param)/api/order-history(.:format)
  // function(options)
  apiOrderHistoryIndexPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"order-history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_payment_purchases => (/:path_prefix)(/:locale_param)/api/payment/purchases(.:format)
  // function(options)
  apiPaymentPurchasesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment",false],[2,[7,"/",false],[2,[6,"purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_payments_apple_pay => (/:path_prefix)(/:locale_param)/api/payments/apple_pay(.:format)
  // function(options)
  apiPaymentsApplePayPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"apple_pay",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_payments_pay_pal => (/:path_prefix)(/:locale_param)/api/payments/pay_pal(.:format)
  // function(options)
  apiPaymentsPayPalPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"pay_pal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_payments_stripe => (/:path_prefix)(/:locale_param)/api/payments/stripe(.:format)
  // function(options)
  apiPaymentsStripePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_payments_stripe_saved_payments => (/:path_prefix)(/:locale_param)/api/payments/stripe/saved_payments(.:format)
  // function(options)
  apiPaymentsStripeSavedPaymentsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"saved_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_payments_stripe_secrets => (/:path_prefix)(/:locale_param)/api/payments/stripe/secrets(.:format)
  // function(options)
  apiPaymentsStripeSecretsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"secrets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_payments_stripe_webhooks => (/:path_prefix)(/:locale_param)/api/payments/stripe/webhooks(.:format)
  // function(options)
  apiPaymentsStripeWebhooksPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"webhooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_pending_purchase => (/:path_prefix)(/:locale_param)/api/pending_purchases/:id(.:format)
  // function(id, options)
  apiPendingPurchasePath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pending_purchases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_pending_purchase_institution => (/:path_prefix)(/:locale_param)/api/pending_purchase_institution/:id(.:format)
  // function(id, options)
  apiPendingPurchaseInstitutionPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pending_purchase_institution",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_product_recommendation => (/:path_prefix)(/:locale_param)/api/product_recommendations/:id(.:format)
  // function(id, options)
  apiProductRecommendationPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_product_recommendations => (/:path_prefix)(/:locale_param)/api/product_recommendations(.:format)
  // function(options)
  apiProductRecommendationsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_recaptcha_verify => (/:path_prefix)(/:locale_param)/api/recaptcha/verify(.:format)
  // function(options)
  apiRecaptchaVerifyPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"recaptcha",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_refunds => (/:path_prefix)(/:locale_param)/api/refunds(.:format)
  // function(options)
  apiRefundsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"refunds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_refunds_initiate => (/:path_prefix)(/:locale_param)/api/refunds/initiate(.:format)
  // function(options)
  apiRefundsInitiatePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"refunds",false],[2,[7,"/",false],[2,[6,"initiate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_related_assets => (/:path_prefix)(/:locale_param)/api/related_assets(.:format)
  // function(options)
  apiRelatedAssetsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"related_assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_store_terms_acceptances => (/:path_prefix)(/:locale_param)/api/store_terms_acceptances(.:format)
  // function(options)
  apiStoreTermsAcceptancesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"store_terms_acceptances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_taxes => (/:path_prefix)(/:locale_param)/api/taxes(.:format)
  // function(options)
  apiTaxesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"taxes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_options => (/:path_prefix)(/:locale_param)/api/user_options(.:format)
  // function(options)
  apiUserOptionsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_carts => /api/v1/carts
  // function(options)
  apiV1CartsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[6,"carts",false]]]]]]),
// api_v1_line_item => /api/v1/line_items/:id
  // function(id, options)
  apiV1LineItemPath: Utils.route([["id",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"line_items",false],[2,[7,"/",false],[3,"id",false]]]]]]]]),
// api_v1_line_items => /api/v1/line_items
  // function(options)
  apiV1LineItemsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[6,"line_items",false]]]]]]),
// api_v1_product => /api/v1/products/:vbid
  // function(vbid, options)
  apiV1ProductPath: Utils.route([["vbid",true]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[3,"vbid",false]]]]]]]]),
// api_v1_regions => /api/v1/regions
  // function(options)
  apiV1RegionsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[6,"regions",false]]]]]]),
// api_v1_search_products => /api/v1/search/products
  // function(options)
  apiV1SearchProductsPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[6,"products",false]]]]]]]]),
// apply_api_promo_codes => (/:path_prefix)(/:locale_param)/api/promo_codes/apply(.:format)
  // function(options)
  applyApiPromoCodesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"promo_codes",false],[2,[7,"/",false],[2,[6,"apply",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// asset => (/:path_prefix)(/:locale_param)/products/:id(.:format)
  // function(id, options)
  assetPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// asset_search => (/:path_prefix)(/:locale_param)/textbooks(/:tier_1_id(/:tier_2_id(/:tier_3_id(/:tier_4_id))))(.:format)
  // function(options)
  assetSearchPath: Utils.route([["path_prefix",false],["locale_param",false],["tier_1_id",false],["tier_2_id",false],["tier_3_id",false],["tier_4_id",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"textbooks",false],[2,[1,[2,[7,"/",false],[2,[3,"tier_1_id",false],[1,[2,[7,"/",false],[2,[3,"tier_2_id",false],[1,[2,[7,"/",false],[2,[3,"tier_3_id",false],[1,[2,[7,"/",false],[3,"tier_4_id",false]],false]]],false]]],false]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// assets => (/:path_prefix)(/:locale_param)/products(.:format)
  // function(options)
  assetsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// authenticate => /authenticate(.:format)
  // function(options)
  authenticatePath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"authenticate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// authorize_api_payments_apple_pay => (/:path_prefix)(/:locale_param)/api/payments/apple_pay/authorize(.:format)
  // function(options)
  authorizeApiPaymentsApplePayPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"apple_pay",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// authorize_api_payments_pay_pal => (/:path_prefix)(/:locale_param)/api/payments/pay_pal/authorize(.:format)
  // function(options)
  authorizeApiPaymentsPayPalPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"pay_pal",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// authorize_api_payments_stripe => (/:path_prefix)(/:locale_param)/api/payments/stripe/authorize(.:format)
  // function(options)
  authorizeApiPaymentsStripePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// balance_api_payments_watchman => (/:path_prefix)(/:locale_param)/api/payments/watchman/balance(.:format)
  // function(options)
  balanceApiPaymentsWatchmanPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"watchman",false],[2,[7,"/",false],[2,[6,"balance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// benefits_for_students => (/:path_prefix)(/:locale_param)/benefits-for-students(.:format)
  // function(options)
  benefitsForStudentsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"benefits-for-students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bookshelf => /bookshelf/home(.:format)
  // function(options)
  bookshelfPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bookshelf",false],[2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bookshelf_features => (/:path_prefix)(/:locale_param)/bookshelf-features(.:format)
  // function(options)
  bookshelfFeaturesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"bookshelf-features",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bookshelf_for_students => (/:path_prefix)(/:locale_param)/bookshelf-for-students(.:format)
  // function(options)
  bookshelfForStudentsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"bookshelf-for-students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bookshelf_read => (/:path_prefix)(/:locale_param)/bookshelf/books/:id(.:format)
  // function(id, options)
  bookshelfReadPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"bookshelf",false],[2,[7,"/",false],[2,[6,"books",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bookshelfgps => (/:path_prefix)(/:locale_param)/bookshelfgps(.:format)
  // function(options)
  bookshelfgpsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"bookshelfgps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bookstore => /bookstore/products/*path(.:format)
  // function(path, options)
  bookstorePath: Utils.route([["path",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bookstore",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[5,[3,"path",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// browser_not_supported => (/:path_prefix)(/:locale_param)/browser-not-supported(.:format)
  // function(options)
  browserNotSupportedPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"browser-not-supported",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_orders => /bulk-orders(.:format)
  // function(options)
  bulkOrdersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk-orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bulk_purchase_request => (/:path_prefix)(/:locale_param)/bulk_purchase_requests/:id(.:format)
  // function(id, options)
  bulkPurchaseRequestPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"bulk_purchase_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_refund_policy => (/:path_prefix)(/:locale_param)/bulk-refund-policy(.:format)
  // function(options)
  bulkRefundPolicyPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"bulk-refund-policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// buy_safe => (/:path_prefix)(/:locale_param)/buy-safe(.:format)
  // function(options)
  buySafePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"buy-safe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_api_payments_apple_pay => (/:path_prefix)(/:locale_param)/api/payments/apple_pay/cancel(.:format)
  // function(options)
  cancelApiPaymentsApplePayPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"apple_pay",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_api_payments_pay_pal => (/:path_prefix)(/:locale_param)/api/payments/pay_pal/cancel(.:format)
  // function(options)
  cancelApiPaymentsPayPalPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"pay_pal",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_two_factor_authentications => (/:path_prefix)(/:locale_param)/two_factor_authentications/cancel(.:format)
  // function(options)
  cancelTwoFactorAuthenticationsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"two_factor_authentications",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cart => (/:path_prefix)(/:locale_param)/cart(.:format)
  // function(options)
  cartPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"cart",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_locale => /change_locale/:locale(.:format)
  // function(locale, options)
  changeLocalePath: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"change_locale",false],[2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_for_duplicates_api_institutions => (/:path_prefix)(/:locale_param)/api/institutions/check_for_duplicates(.:format)
  // function(options)
  checkForDuplicatesApiInstitutionsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[6,"check_for_duplicates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// checkout => (/:path_prefix)(/:locale_param)/checkout(.:format)
  // function(options)
  checkoutPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"checkout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// children_api_institution => (/:path_prefix)(/:locale_param)/api/institutions/:id/children(.:format)
  // function(id, options)
  childrenApiInstitutionPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"children",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// coach_me => (/:path_prefix)(/:locale_param)/coach-me(.:format)
  // function(options)
  coachMePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"coach-me",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_us => (/:path_prefix)(/:locale_param)/contact_us(.:format)
  // function(options)
  contactUsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"contact_us",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cookies => (/:path_prefix)(/:locale_param)/cookies(.:format)
  // function(options)
  cookiesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"cookies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// country_selector => /country-selector(.:format)
  // function(options)
  countrySelectorPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"country-selector",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// coursesmart_page_missing => (/:path_prefix)(/:locale_param)/coursesmart-page-missing(.:format)
  // function(options)
  coursesmartPageMissingPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"coursesmart-page-missing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// coursesmart_redirects => (/:path_prefix)(/:locale_param)/coursesmart_redirects(.:format)
  // function(options)
  coursesmartRedirectsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"coursesmart_redirects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// csm_apps_help => (/:path_prefix)(/:locale_param)/csm-apps-help(.:format)
  // function(options)
  csmAppsHelpPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"csm-apps-help",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// csu_rent_digital => (/:path_prefix)(/:locale_param)/csu-rent-digital(.:format)
  // function(options)
  csuRentDigitalPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"csu-rent-digital",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// custom_asset => (/:path_prefix)(/:locale_param)/custom/:id(.:format)
  // function(id, options)
  customAssetPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// decline_api_product_recommendation => (/:path_prefix)(/:locale_param)/api/product_recommendations/:id/decline(.:format)
  // function(id, options)
  declineApiProductRecommendationPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"decline",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// digital_textbooks => (/:path_prefix)(/:locale_param)/digital-textbooks(.:format)
  // function(options)
  digitalTextbooksPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"digital-textbooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// distribution_partners => (/:path_prefix)(/:locale_param)/distribution-partners(.:format)
  // function(options)
  distributionPartnersPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"distribution-partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_admin_permission => /admin/permissions/:id/edit(.:format)
  // function(id, options)
  editAdminPermissionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_admin_permission => (/:path_prefix)(/:locale_param)/api/admin/permissions/:id/edit(.:format)
  // function(id, options)
  editApiAdminPermissionPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_product_recommendation => (/:path_prefix)(/:locale_param)/api/product_recommendations/:id/edit(.:format)
  // function(id, options)
  editApiProductRecommendationPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_manage_sso => /manage_sso/:id/edit(.:format)
  // function(id, options)
  editManageSsoPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"manage_sso",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_roles => (/:path_prefix)(/:locale_param)/roles/edit(.:format)
  // function(options)
  editRolesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// educator => (/:path_prefix)(/:locale_param)/educator(.:format)
  // function(options)
  educatorPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"educator",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_needs_verification => (/:path_prefix)(/:locale_param)/email-needs-verification(.:format)
  // function(options)
  emailNeedsVerificationPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"email-needs-verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_verification_failed => (/:path_prefix)(/:locale_param)/email-verification-failed(.:format)
  // function(options)
  emailVerificationFailedPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"email-verification-failed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// execute_api_payments_pay_pal => (/:path_prefix)(/:locale_param)/api/payments/pay_pal/execute(.:format)
  // function(options)
  executeApiPaymentsPayPalPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"pay_pal",false],[2,[7,"/",false],[2,[6,"execute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// execute_api_payments_stripe => (/:path_prefix)(/:locale_param)/api/payments/stripe/execute(.:format)
  // function(options)
  executeApiPaymentsStripePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"execute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// execute_api_payments_watchman => (/:path_prefix)(/:locale_param)/api/payments/watchman/execute(.:format)
  // function(options)
  executeApiPaymentsWatchmanPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"watchman",false],[2,[7,"/",false],[2,[6,"execute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// faqs => (/:path_prefix)(/:locale_param)/faqs(.:format)
  // function(options)
  faqsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"faqs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// forgot_password => (/:path_prefix)(/:locale_param)/forgot_password(/:password_reset_email)(.:format)
  // function(options)
  forgotPasswordPath: Utils.route([["path_prefix",false],["locale_param",false],["password_reset_email",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"forgot_password",false],[2,[1,[2,[7,"/",false],[3,"password_reset_email",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// getsupport => (/:path_prefix)(/:locale_param)/getsupport(.:format)
  // function(options)
  getsupportPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"getsupport",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// guest_checkout => (/:path_prefix)(/:locale_param)/guest_checkout(.:format)
  // function(options)
  guestCheckoutPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"guest_checkout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// instructor_referral => (/:path_prefix)(/:locale_param)/referral(.:format)
  // function(options)
  instructorReferralPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"referral",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// k12 => (/:path_prefix)(/:locale_param)/k12(.:format)
  // function(options)
  k12Path: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"k12",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login => (/:path_prefix)(/:locale_param)/login(.:format)
  // function(options)
  loginPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// logout => (/:path_prefix)(/:locale_param)/logout(.:format)
  // function(options)
  logoutPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_permission => /admin/permissions/new(.:format)
  // function(options)
  newAdminPermissionPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_admin_permission => (/:path_prefix)(/:locale_param)/api/admin/permissions/new(.:format)
  // function(options)
  newApiAdminPermissionPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_product_recommendation => (/:path_prefix)(/:locale_param)/api/product_recommendations/new(.:format)
  // function(options)
  newApiProductRecommendationPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_purchase_order_request => (/:path_prefix)(/:locale_param)/purchase-order-request(.:format)
  // function(options)
  newPurchaseOrderRequestPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"purchase-order-request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// order_complete => (/:path_prefix)(/:locale_param)/order_complete(.:format)
  // function(options)
  orderCompletePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"order_complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// order_history => (/:path_prefix)(/:locale_param)/order-history/:id(.:format)
  // function(id, options)
  orderHistoryPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"order-history",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order_history_index => (/:path_prefix)(/:locale_param)/order-history(.:format)
  // function(options)
  orderHistoryIndexPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"order-history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pending_api_product_recommendations => (/:path_prefix)(/:locale_param)/api/product_recommendations/pending(.:format)
  // function(options)
  pendingApiProductRecommendationsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[2,[7,"/",false],[2,[6,"pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_purchase_verification_email => (/:path_prefix)(/:locale_param)/post_purchase_verification_email(.:format)
  // function(options)
  postPurchaseVerificationEmailPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"post_purchase_verification_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// print_receipt => (/:path_prefix)(/:locale_param)/print_receipt(.:format)
  // function(options)
  printReceiptPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"print_receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// privacy => (/:path_prefix)(/:locale_param)/privacy(.:format)
  // function(options)
  privacyPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pro_services => (/:path_prefix)(/:locale_param)/pro-services(.:format)
  // function(options)
  proServicesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"pro-services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// process_3d_secure_result_api_payments_stripe => (/:path_prefix)(/:locale_param)/api/payments/stripe/process_3d_secure_result(.:format)
  // function(options)
  process3dSecureResultApiPaymentsStripePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"process_3d_secure_result",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// purchase_order_request => (/:path_prefix)(/:locale_param)/purchase-order-request(.:format)
  // function(options)
  purchaseOrderRequestPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"purchase-order-request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// purchase_order_request_confirmation => (/:path_prefix)(/:locale_param)/purchase-order-request/confirmation(.:format)
  // function(options)
  purchaseOrderRequestConfirmationPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"purchase-order-request",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// redeem => (/:path_prefix)(/:locale_param)/redeem(.:format)
  // function(options)
  redeemPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"redeem",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// redeem_success => (/:path_prefix)(/:locale_param)/redeem_success(.:format)
  // function(options)
  redeemSuccessPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"redeem_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// refund_policy => (/:path_prefix)(/:locale_param)/refund-policy(.:format)
  // function(options)
  refundPolicyPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"refund-policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// region_recommendation_api_regions => (/:path_prefix)(/:locale_param)/api/regions/region_recommendation(.:format)
  // function(options)
  regionRecommendationApiRegionsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[6,"region_recommendation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// remove_api_promo_codes => (/:path_prefix)(/:locale_param)/api/promo_codes/remove(.:format)
  // function(options)
  removeApiPromoCodesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"promo_codes",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// resend_email_verification => (/:path_prefix)(/:locale_param)/resend_email_verification(.:format)
  // function(options)
  resendEmailVerificationPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"resend_email_verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reset_password => (/:path_prefix)(/:locale_param)/reset_password(.:format)
  // function(options)
  resetPasswordPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roles => (/:path_prefix)(/:locale_param)/roles(.:format)
  // function(options)
  rolesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  rootPath: Utils.route([], {}, [7,"/",false]),
// send_access_code_api_access_codes => (/:path_prefix)(/:locale_param)/api/access_codes/send_access_code(.:format)
  // function(options)
  sendAccessCodeApiAccessCodesPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"access_codes",false],[2,[7,"/",false],[2,[6,"send_access_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sessions => (/:path_prefix)(/:locale_param)/sessions(.:format)
  // function(options)
  sessionsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// setup_two_factor_authentications => (/:path_prefix)(/:locale_param)/two_factor_authentications/setup(.:format)
  // function(options)
  setupTwoFactorAuthenticationsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"two_factor_authentications",false],[2,[7,"/",false],[2,[6,"setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// show_conversion_requests => (/:path_prefix)(/:locale_param)/conversion_requests(/:token)(.:format)
  // function(options)
  showConversionRequestsPath: Utils.route([["path_prefix",false],["locale_param",false],["token",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"conversion_requests",false],[2,[1,[2,[7,"/",false],[3,"token",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// show_product_by_sku => (/:path_prefix)(/:locale_param)/show/:sku(.:format)
  // function(sku, options)
  showProductBySkuPath: Utils.route([["path_prefix",false],["locale_param",false],["sku",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"show",false],[2,[7,"/",false],[2,[3,"sku",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiqWebPath: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// sign_up => (/:path_prefix)(/:locale_param)/sign_up(.:format)
  // function(options)
  signUpPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sign_up_account_already_exists => (/:path_prefix)(/:locale_param)/sign_up/account_already_exists(.:format)
  // function(options)
  signUpAccountAlreadyExistsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"account_already_exists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sign_up_capture_email => (/:path_prefix)(/:locale_param)/sign_up/capture_email(.:format)
  // function(options)
  signUpCaptureEmailPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"capture_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sign_up_create_user => (/:path_prefix)(/:locale_param)/sign_up/create_user(.:format)
  // function(options)
  signUpCreateUserPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"sign_up",false],[2,[7,"/",false],[2,[6,"create_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// student_etextbooks => (/:path_prefix)(/:locale_param)/student-etextbooks(.:format)
  // function(options)
  studentEtextbooksPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"student-etextbooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// table_of_contents_asset => (/:path_prefix)(/:locale_param)/products/:id/table_of_contents(.:format)
  // function(id, options)
  tableOfContentsAssetPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"table_of_contents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// terms => (/:path_prefix)(/:locale_param)/terms(.:format)
  // function(options)
  termsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// terms_of_purchase => (/:path_prefix)(/:locale_param)/terms-of-purchase(.:format)
  // function(options)
  termsOfPurchasePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"terms-of-purchase",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// testimonials => (/:path_prefix)(/:locale_param)/testimonials(.:format)
  // function(options)
  testimonialsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"testimonials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// two_factor_authentication_setup_initialize => (/:path_prefix)(/:locale_param)/two_factor_authentications(:initialize_setup)(.:format)
  // function(options)
  twoFactorAuthenticationSetupInitializePath: Utils.route([["path_prefix",false],["locale_param",false],["initialize_setup",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"two_factor_authentications",false],[2,[1,[3,"initialize_setup",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// two_factor_setup_complete => (/:path_prefix)(/:locale_param)/two-factor-setup-complete(.:format)
  // function(options)
  twoFactorSetupCompletePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"two-factor-setup-complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// undo_api_product_recommendation => (/:path_prefix)(/:locale_param)/api/product_recommendations/:id/undo(.:format)
  // function(id, options)
  undoApiProductRecommendationPath: Utils.route([["path_prefix",false],["locale_param",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_recommendations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"undo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// universal_logins => /universal_logins(.:format)
  // function(options)
  universalLoginsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"universal_logins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_slack_admin_ghost_inspector => /admin/ghost_inspector/update_slack(.:format)
  // function(options)
  updateSlackAdminGhostInspectorPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ghost_inspector",false],[2,[7,"/",false],[2,[6,"update_slack",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// verify_email => (/:path_prefix)(/:locale_param)/verify-email(.:format)
  // function(options)
  verifyEmailPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"verify-email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// verify_two_factor_authentications => (/:path_prefix)(/:locale_param)/two_factor_authentications/verify(.:format)
  // function(options)
  verifyTwoFactorAuthenticationsPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"two_factor_authentications",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// wallet_authorize_api_payments_stripe => (/:path_prefix)(/:locale_param)/api/payments/stripe/wallet_authorize(.:format)
  // function(options)
  walletAuthorizeApiPaymentsStripePath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"wallet_authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// white_label_store_demo => (/:path_prefix)(/:locale_param)/white-label-store/demo(.:format)
  // function(options)
  whiteLabelStoreDemoPath: Utils.route([["path_prefix",false],["locale_param",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"path_prefix",false]],false],[2,[1,[2,[7,"/",false],[3,"locale_param",false]],false],[2,[7,"/",false],[2,[6,"white-label-store",false],[2,[7,"/",false],[2,[6,"demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("routes" + ".defaults is removed. Use " + "routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call({});

